import { type SandpackTheme } from '@codesandbox/sandpack-react';
import { z } from 'zod';

export const templates = [
  'react',
  'react-ts',
  'vanilla',
  'vanilla-ts',
  'static',
] as const;

/* Project Schemas */
const EditorComponentSchema = z.object({
  type: z.literal('editor'),
  data: z
    .object({
      showTabs: z.boolean().default(false),
      showLineNumbers: z.boolean().default(false),
      showInlineErrors: z.boolean().default(false),
      showRunButton: z.boolean().default(false),
      wrapContent: z.boolean().default(false),
      closableTabs: z.boolean().default(false),
      readOnly: z.boolean().default(false),
      showReadOnly: z.boolean().default(false),
    })
    .default({}),
});

const PreviewComponentSchema = z.object({
  type: z.literal('preview'),
  data: z
    .object({
      showNavigator: z.boolean().default(false),
      showOpenInCodeSandbox: z.boolean().default(false),
      showRefreshButton: z.boolean().default(false),
      showSandpackErrorOverlay: z.boolean().default(false),
      startRoute: z.string().optional(),
    })
    .default({}),
});

const ConsoleComponentSchema = z.object({
  type: z.literal('console'),
  data: z
    .object({
      showHeader: z.boolean().default(false),
      showSyntaxError: z.boolean().default(false),
      showSetupProgress: z.boolean().default(false),
      maxMessageCount: z.number().default(1),
      resetOnPreviewRestart: z.boolean().default(false),
      // This is automatically set in the dune-embed and is only here for completeness.
      standalone: z.boolean().default(false),
    })
    .default({}),
});

export const ComponentSchemas = z.discriminatedUnion('type', [
  EditorComponentSchema,
  PreviewComponentSchema,
  ConsoleComponentSchema,
]);

export const ProjectSchema = z.object({
  id: z.string(),
  name: z.string(),
  core: z.object({
    files: z.record(
      z.object({
        code: z.string(),
        hidden: z.boolean().optional(),
        active: z.boolean().optional(),
        readOnly: z.boolean().optional(),
      }),
    ),
    template: z.enum(templates),
  }),
  display: z.object({
    components: z.record(ComponentSchemas),
    general: z.object({
      activeFile: z.string().optional(),
      visibleFiles: z.array(z.string()).optional(),
      theme: z.string().optional(),
      enableFormatting: z.boolean().optional(),
      externalResources: z.array(z.string()).optional(),
    }),
    layouts: z.object({
      small: z.string(),
      medium: z.string().optional(),
      large: z.string().optional(),
    }),
  }),
});

export type ProjectSchemaModel = z.infer<typeof ProjectSchema>;

export const FullProjectSchema = ProjectSchema.merge(
  z.object({
    paid: z
      .object({
        /* Branding Features */
        removeBranding: z.boolean().optional(),
        customBrandLink: z.string().url().optional(),
        customBrandText: z.string().max(25).optional(),
      })
      .optional(),
  }),
);

export type FullProjectSchemaModel = z.infer<typeof FullProjectSchema>;

export const defaultProject = ProjectSchema.parse({
  id: '',
  name: '',
  core: { files: {}, template: 'vanilla' },
  display: {
    components: {
      Editor: {
        type: 'editor',
        data: {
          showTabs: true,
          showLineNumbers: true,
          showRunButton: true,
          wrapContent: true,
          closableTabs: true,
        },
      },
    },
    general: {},
    layouts: { small: "'Editor'" },
  },
});

/* Themes */
const SandpackSyntaxStyleSchema = z.object({
  color: z.string().optional(),
  fontStyle: z.enum(['normal', 'italic']).optional(),
  fontWeight: z
    .enum([
      'normal',
      'bold',
      '100',
      '200',
      '300',
      '400',
      '500',
      '600',
      '700',
      '800',
      '900',
    ])
    .optional(),
  textDecoration: z
    .enum(['none', 'underline', 'line-through', 'underline line-through'])
    .optional(),
});

export const SandpackThemeSchema = z.object({
  colors: z.object({
    surface1: z.string(),
    surface2: z.string(),
    surface3: z.string(),
    disabled: z.string(),
    base: z.string(),
    clickable: z.string(),
    hover: z.string(),
    accent: z.string(),
    error: z.string().optional(),
    errorSurface: z.string().optional(),
    warning: z.string().optional(),
    warningSurface: z.string().optional(),
  }),
  syntax: z.object({
    plain: z.string().or(SandpackSyntaxStyleSchema),
    comment: z.string().or(SandpackSyntaxStyleSchema),
    keyword: z.string().or(SandpackSyntaxStyleSchema),
    definition: z.string().or(SandpackSyntaxStyleSchema),
    punctuation: z.string().or(SandpackSyntaxStyleSchema),
    property: z.string().or(SandpackSyntaxStyleSchema),
    tag: z.string().or(SandpackSyntaxStyleSchema),
    static: z.string().or(SandpackSyntaxStyleSchema),
    string: z.string().or(SandpackSyntaxStyleSchema).optional(),
  }),
  font: z.object({
    body: z.string(),
    mono: z.string(),
    size: z.string(),
    lineHeight: z.string(),
  }),
});

function buildThemes<ThemeKeys extends string>(
  themes: Record<ThemeKeys, { name: string; theme: SandpackTheme }>,
) {
  return themes;
}

export const defaultThemes = buildThemes({
  kempoLight: {
    name: 'Kempo (Light)',
    theme: {
      colors: {
        surface1: '#f8f9fb',
        surface2: '#EBEDF0',
        surface3: '#e4e7eb',
        clickable: '#737373',
        base: '#323232',
        disabled: '#C5C5C5',
        hover: '#1f2933',
        accent: '#2e7692',
      },
      syntax: {
        plain: '#1F2933',
        comment: {
          color: '#A7B6C2',
          fontStyle: 'italic',
        },
        keyword: '#1A56DB',
        tag: '#1A56DB',
        punctuation: '#394b59',
        definition: '#A23DAD',
        property: '#2e7692',
        static: '#1A56DB',
        string: '#1992D4',
      },
      font: {
        body: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
        mono: '"Fira Mono", "DejaVu Sans Mono", Menlo, Consolas, "Liberation Mono", Monaco, "Lucida Console", monospace',
        size: '16px',
        lineHeight: '20px',
      },
    },
  },
  kempoDark: {
    name: 'Kempo (Dark)',
    theme: {
      colors: {
        surface1: '#1a1b26',
        surface2: '#565f89',
        surface3: '#363c59',
        clickable: '#c0caf5',
        base: '#c0caf5',
        disabled: '#C5C5C5',
        hover: '#9aa5ce',
        accent: '#bb9af7',
        error: '#c0caf5',
        errorSurface: '#565f89',
      },
      syntax: {
        plain: '#9aa5ce',
        comment: {
          color: '#a8b3e0',
          fontStyle: 'italic',
        },
        keyword: '#f7768e',
        tag: '#f7768e',
        punctuation: '#c0caf5',
        definition: '#bb9af7',
        property: '#7dcfff',
        static: '#ff9e64',
        string: '#9ece6a',
      },
      font: {
        body: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
        mono: '"Fira Mono", "DejaVu Sans Mono", Menlo, Consolas, "Liberation Mono", Monaco, "Lucida Console", monospace',
        size: '16px',
        lineHeight: '20px',
      },
    },
  },
  dracula: {
    name: 'Dracula',
    theme: {
      colors: {
        surface1: '#282a36',
        surface2: '#44475a',
        surface3: '#44475a',
        clickable: '#6272a4',
        base: '#f8f8f2',
        disabled: '#6272a4',
        hover: '#f8f8f2',
        accent: '#bd93f9',
        error: '#f8f8f2',
        errorSurface: '#44475a',
      },
      syntax: {
        plain: '#f8f8f2',
        comment: {
          color: '#6272a4',
          fontStyle: 'italic',
        },
        keyword: '#ff79c6',
        tag: '#ff79c6',
        punctuation: '#ff79c6',
        definition: '#f8f8f2',
        property: '#50fa7b',
        static: '#bd93f9',
        string: '#f1fa8c',
      },
      font: {
        body: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
        mono: '"Fira Mono", "DejaVu Sans Mono", Menlo, Consolas, "Liberation Mono", Monaco, "Lucida Console", monospace',
        size: '13px',
        lineHeight: '20px',
      },
    },
  },
  gruvboxDark: {
    name: 'Gruvbox (dark)',
    theme: {
      colors: {
        surface1: '#282828',
        surface2: '#3c3836',
        surface3: '#3c3836',
        clickable: '#ebdbb2',
        base: '#ebdbb2',
        disabled: '#928374',
        hover: '#fe8019',
        accent: '#d65d0e',
        error: '#ff453a',
        errorSurface: '#3c3836',
      },
      syntax: {
        plain: '#ebdbb2',
        comment: {
          color: '#928374',
          fontStyle: 'italic',
        },
        keyword: '#ff453a',
        tag: '#83a598',
        punctuation: '#ebdbb2',
        definition: '#83a598',
        property: '#fabd2f',
        static: '#ebdbb2',
        string: '#b8bb26',
      },
      font: {
        body: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
        mono: '"Fira Mono", "DejaVu Sans Mono", Menlo, Consolas, "Liberation Mono", Monaco, "Lucida Console", monospace',
        size: '13px',
        lineHeight: '20px',
      },
    },
  },
});
